import { useEffect, useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { GlobalContext } from "context/context/GlobalContext"
import { useHistory, useParams } from "react-router-dom"
import Loading from "overrides/theme/entel/base/components/Commons/Loading/Loading"
import SearchForm from "overrides/theme/entel/base/components/SearchForm/SearchForm"
import { OrderDetailStyled } from "overrides/theme/entel/base/pages/DetailOrderScreens/styles/OrderDetail.style"
import OrderDetails from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderDetails"
import { useEnv } from "hooks"
import usePostMiddlewareQuery from "../../hooks/v4/usePostMiddlewareQuery"
import { AuthContext } from "context/context"
import logo_omnix from "assets/images/logo-omnix3.png"

const OrderDetailScreen = () => {
  const {
    state: { user },
  } = useContext(AuthContext)
  const { t } = useTranslation()
  const { permissions } = useEnv()
  const { displayHeaderRouteName } = useContext(GlobalContext)
  const { shippingtype, shippingGroupId, id }: any = useParams()
  const history = useHistory()
  const [loading, set_loading] = useState(false)
  const [data, set_data]: any = useState({})
  const { post_middleware_query_action } = usePostMiddlewareQuery()

  const handleClickSearch = (e: any) => history.push(`/search/${e.optionFilter}/${e.query}`)

  useEffect(() => {
    let shippingTypeTitle = data?._order?.shippingType
    displayHeaderRouteName.dispatch(shippingTypeTitle ? `Orden ${t(shippingTypeTitle)}` : "Detalle de Órden")
    //eslint-disable-next-line
  }, [data])

  useEffect(() => {
    post_req()
    //eslint-disable-next-line
  }, [])

  const post_req = async () => {
    let res: any = null
    let res_grouped: any = {}
    set_loading(true)

    res = await post_middleware_query_action({
      skipRecords: 0,
      maxRecords: 1,
      entityType: "ShippingGroup",
      filter: {
        id: shippingGroupId,
        shippingType: shippingtype.toUpperCase(),
      },
    })
    res_grouped = { ...res_grouped, _sg: { ...res?.data?.message?.records[0] } }

    res = await post_middleware_query_action({
      skipRecords: 0,
      maxRecords: 1,
      entityType: "Order",
      filter: {
        id,
        shippingType: shippingtype.toUpperCase(),
      },
    })
    res_grouped = { ...res_grouped, _order: { ...res?.data?.message?.records[0] } }

    const _items = res_grouped._sg?.logisticPlan?.items || []

    res_grouped = { ...res_grouped, _items }

    set_data(res_grouped)
    set_loading(false)
  }

  const template = (
    <>
      {loading ? (
        <Loading defaultOpened={loading} />
      ) : (
        <>
          {permissions.get_permission("global", "block-search-details") && (
            <SearchForm onClickSearch={handleClickSearch} backButton={true} />
          )}
          {data.hasOwnProperty("_sg") && (
            <OrderDetails data={data} set_loading={set_loading} user={user} update_info_detail={post_req} />
          )}
          <div className="cont__order-detail__footer">
            <p>Powered by</p>
            <img src={logo_omnix} alt="logo" className="logo_omnix" />
          </div>
          <style jsx>{OrderDetailStyled}</style>
        </>
      )}
    </>
  )

  return template
}

export default OrderDetailScreen
