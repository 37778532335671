import { memo } from "react"
import { useParams } from "react-router-dom"
import { IconArrowRigth, IconIoStorefront } from "theme/common/Icon.styled"
import { ICardOrderItem } from "interfaces/IOrder"
import { useTranslation } from "react-i18next"
import { cardOrderItemStyle } from "overrides/theme/entel/base/components/Core/Order/CardOrder/CardOrderItem.styled"
import translatesObj from "overrides/theme/entel/base/translates/translation.json"
import { formatDate, formatTime } from "helpers/FormatDateHelper"
import ReactTooltip from "react-tooltip"
import { PiArrowsLeftRightBold } from "react-icons/pi";

const CardOrderItem = memo(
  ({
    id,
    orderId,
    salesChannelId,
    source,
    target,
    orderCreationDate,
    logisticPlan,
    shippingType,
    slotDelivery,
    orderType,
    deliveryType,
    estimatedDeliveryDate,
    _omnixStateMachine,
    canSelect = false,
    handleClickSelectOrder,
    isCheckedOrder,
    showStatus,
    prepicking_mode,
    columns,
    handle_sg_to_prepicking,
    is_checked_to_prepick,
    currentDisplayList = "grid",
  }: ICardOrderItem) => {
    const { t } = useTranslation()
    const { shippingtype: shippingtype_param }: any = useParams()
    let paramType = _omnixStateMachine?.currentState?.state?.id as string
    paramType = paramType?.toLocaleLowerCase()
    let shipping_type = shippingtype_param || shippingType?.toLocaleLowerCase()
    const infoSgToPrint = { id, orderId, source, target }
    const statusses: any = translatesObj

    return (
      <>
        {currentDisplayList === "list" && prepicking_mode ? (
          <div className="card-order-item__container--prepicking">
            <div className="card-order-item__container--prepicking--first-item">
              <label className="card-order-item__check-container">
                <input
                  type="checkbox"
                  id={salesChannelId}
                  name={salesChannelId}
                  onChange={(ev) => handle_sg_to_prepicking(ev)}
                  checked={is_checked_to_prepick}
                />
                <span className="card-order-item__check-checkmark"></span>
              </label>
              <div className="flex flex-row gap-2">
                {orderType === "Portabilidad" ? (
                  <span>
                    <ReactTooltip />
                    <PiArrowsLeftRightBold
                      data-tip="Portabilidad"
                      style={{ color: "#002eff", fontSize: "1.5rem", margin: "0 0.5rem 0 1rem" }}
                    />
                  </span>
                ) : (
                  <span style={{ width: "1rem", margin: "0 0.5rem" }}></span>
                )}
                <div className="">
                  <ReactTooltip />
                  <IconIoStorefront data-tip={t(shippingType)} className="card-order-item__header-icon" color="#002eff" />
                </div>
              </div>
            </div>
            <p className="card-order-item__body-card-item-value--prepicking">{salesChannelId}</p>
            <p className="card-order-item__body-card-item-value--prepicking">
              {logisticPlan?.items[0]
                ? `${logisticPlan?.items[0]?.sku} / ${logisticPlan?.items[0]?.quantity} Und.`
                : "-"}
            </p>
            <p className="card-order-item__body-card-item-value--prepicking">
              {logisticPlan?.items[1]
                ? `${logisticPlan?.items[1]?.sku} / ${logisticPlan?.items[1]?.quantity} Und.`
                : "-"}
            </p>
            <p className="card-order-item__body-card-item-value--prepicking">
              {logisticPlan?.items[2]
                ? `${logisticPlan?.items[2]?.sku} / ${logisticPlan?.items[2]?.quantity} Und.`
                : "-"}
            </p>
            <p className="card-order-item__body-card-item-value--prepicking">
              {logisticPlan?.items[3]
                ? `${logisticPlan?.items[3]?.sku} / ${logisticPlan?.items[3]?.quantity} Und.`
                : "-"}
            </p>
          </div>
        ) : (
          <div
            className={
              currentDisplayList === "grid"
                ? "card-order-item__container"
                : "card-order-item__container card-order-item__lv__container"
            }
          >
            {canSelect && (
              <div
                className={
                  currentDisplayList === "grid"
                    ? "card-order-item__content-order-checked"
                    : "card-order-item__content-order-checked card-order-item__lv__content-order-checked"
                }
              >
                <label className="card-order-item__check-container">
                  <input
                    type="checkbox"
                    id={id}
                    name={id}
                    onChange={(ev) => handleClickSelectOrder(ev, infoSgToPrint)}
                    checked={isCheckedOrder}
                  />
                  <span className="card-order-item__check-checkmark"></span>
                </label>
              </div>
            )}
            <a
              className={
                currentDisplayList === "grid"
                  ? "card-order-item__content-order-info"
                  : "card-order-item__content-order-info card-order-item__lv__content-order-info"
              }
              href={`/order/${shipping_type}/${id}/${orderId}`}
            >
              <div
                className={
                  currentDisplayList === "grid"
                    ? "card-order-item__content-item-order"
                    : "card-order-item__content-item-order card-order-item__lv__content-item-order"
                }
              >
                <div
                  className={
                    currentDisplayList === "grid"
                      ? "card-order-item__header-card"
                      : "card-order-item__header-card card-order-item__lv__header-card"
                  }
                >
                  {orderType === "Portabilidad" ? (
                    <span>
                      <ReactTooltip />
                      <PiArrowsLeftRightBold
                        data-tip="Portabilidad"
                        style={{ color: "#002eff", fontSize: "1.2rem", margin: "0 0.5rem" }}
                      />
                    </span>
                  ) : (
                    <span style={{ width: "1rem", margin: "0 0.5rem" }}></span>
                  )}
                  <div className="card-order-item__header-left">
                    <ReactTooltip />
                    <IconIoStorefront
                      data-tip={t(shippingType)}
                      className="card-order-item__header-icon"
                      color="#002eff"
                    />
                    <p className="card-order-item__header-title">{t(shippingType)}</p>
                  </div>
                  <IconArrowRigth className="card-order-item__header-arrow" color="#002eff" />
                </div>
                <div
                  className={
                    currentDisplayList === "grid"
                      ? "card-order-item__body-card"
                      : "card-order-item__body-card card-order-item__lv__body-card"
                  }
                >
                  <div
                    className={
                      currentDisplayList === "grid"
                        ? "card-order-item__body-card-item--titles"
                        : "card-order-item__body-card-item--titles card-order-item__lv__body-card-item--titles"
                    }
                  >
                    {/* <p className="card-order-item__body-card-item-title">PORTABILIDAD</p> */}
                    {columns.map((col: string, i: number) => (
                      <p className="card-order-item__body-card-item-title" key={i}>
                        {col}
                      </p>
                    ))}
                    {showStatus && <p className="card-order-item__body-card-item-title">ESTADO</p>}
                  </div>
                  <div
                    className={
                      currentDisplayList === "grid"
                        ? "card-order-item__body-card-item--values"
                        : "card-order-item__body-card-item--values card-order-item__lv__body-card-item--values"
                    }
                  >
                    {/* <p className="card-order-item__body-card-item-value">
                    {orderType === "Portabilidad" ? <PiArrowsLeftRightBold style={{fontSize: "1rem"}} /> : "No"}
                  </p> */}
                    <p className="card-order-item__body-card-item-value">{salesChannelId || "-"}</p>
                    <p className="card-order-item__body-card-item-value">{deliveryType || "-"}</p>
                    <p className="card-order-item__body-card-item-value">
                      {logisticPlan.route ? logisticPlan.route[0]?.courier?.id : "-"}
                    </p>
                    <p className="card-order-item__body-card-item-value">{target?.name}</p>
                    <p className="card-order-item__body-card-item-value">
                      {formatDate(orderCreationDate || "")}, {formatTime(new Date(orderCreationDate) || "")}
                    </p>
                    <p className="card-order-item__body-card-item-value">
                      {slotDelivery
                        ? t(
                            `${slotDelivery?.slotType} / ${formatTime(slotDelivery?.initialHour)} - ${formatTime(
                              slotDelivery?.finalHour,
                            )}`,
                          )
                        : "-"}
                    </p>
                    <p className="card-order-item__body-card-item-value">
                      {logisticPlan.route ? logisticPlan.route[0]?.target?.data?.criteria3 : "-"}
                    </p>
                    <p className="card-order-item__body-card-item-value">{formatDate(estimatedDeliveryDate || "")}</p>
                    <p className="card-order-item__body-card-item-value">
                      <ReactTooltip />
                      <span data-tip={source?.name || ""}>{source?.id ? source?.id : "-"}</span>
                    </p>
                    {showStatus && (
                      <p className="card-order-item__body-card-item-value">
                        {statusses[paramType?.toUpperCase()]?.title || paramType?.toUpperCase()}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </a>
          </div>
        )}
        <style jsx>{cardOrderItemStyle}</style>
      </>
    )
  },
)

export default CardOrderItem
