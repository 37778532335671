import { useTranslation } from "react-i18next"
import { formatDate, formatTime } from "helpers/FormatDateHelper"
import LabelGenerateDocuments from "overrides/theme/entel/base/components/Commons/LabelGenerateDocuments"

const OrderInfoPrint = (props: any) => {
  const { t } = useTranslation()
  const { data, courierName } = props

  const currentStatus = data?._sg?._omnixStateMachine?.currentState?.state?.name

  const enviameLabel = data?._sg?.enviameLabel ? data?._sg?.enviameLabel[0] : null
  const enviameDocsProperty =
    enviameLabel && Object.values(enviameLabel).some((doc: any) => Boolean(doc)) ? true : false

  const isGeneratingDTE = !data?._sg?.dte?.folio ? true : false
  const isGeneratingEnviameDocs = !enviameDocsProperty ? true : false

  const template = (
    <section className="order__main__detail">
      <h2>{t("Guía de despacho")}</h2>
      <article className="order__main__detail__summary">
        <h3>Información General</h3>
        <div>
          <p>{t("Fecha de estado actual")}:</p>
          <p>
            <strong>{formatDate(data?._sg?._omnixStateMachine?.currentState?.date)}</strong>
          </p>
        </div>
        {data?._order?.courier?.enviame?.data?.carrier ? (
          <div>
            <p>{t("Transportista")}:</p>
            <p>
              <strong>{data?._order?.courier?.enviame?.data?.carrier}</strong>
            </p>
          </div>
        ) : null}
        {data?._order?.deliveryType && (
          <div>
            <p>{t("Tipo de entrega")}:</p>
            <p>
              <strong>
                {t(
                  `${data?._order?.slotDelivery?.slotType} / ${formatTime(
                    data?._order?.slotDelivery?.initialHour,
                  )} - ${formatTime(data?._order?.slotDelivery?.finalHour)}`,
                )}
              </strong>
            </p>
          </div>
        )}
        <div>
          <p>{t("DTE Folio")}:</p>
          <LabelGenerateDocuments show_loader={Boolean(isGeneratingDTE)} currentStatus={currentStatus}>
            {data?._sg?.dte?.url?.includes("http") ? (
              <a href={data?._sg?.dte?.url} target="_blank" rel="noreferrer">
                <strong className="order__main__detail__folio__url">{data?._sg?.dte?.folio}</strong>
              </a>
            ) : data?._sg?.dte?.url?.includes("false") ? (
              <p>N/A</p>
            ) : (
              <p>No se generó el documento</p>
            )}
          </LabelGenerateDocuments>
        </div>
        {courierName !== "BRIGHTCELL" ? (
          <>
            <div>
              <p>{t("Enviame Documentos")}:</p>
              <div>
                <LabelGenerateDocuments show_loader={Boolean(isGeneratingEnviameDocs)} currentStatus={currentStatus}>
                  {enviameLabel &&
                  Object.values(enviameLabel).some((doc: any) => Boolean(doc?.toString()?.includes("http"))) ? (
                    <div>
                      <p>
                        <a href={enviameLabel?.labels} target="_blank" rel="noreferrer">
                          <strong className="order__main__detail__folio__url">Etiqueta</strong>
                        </a>
                      </p>
                      <p>
                        <a href={enviameLabel?.summary} target="_blank" rel="noreferrer">
                          <strong className="order__main__detail__folio__url">Manifiesto</strong>
                        </a>
                      </p>
                    </div>
                  ) : enviameLabel &&
                    Object.values(enviameLabel).some((doc: any) => Boolean(doc?.toString()?.includes("false"))) ? (
                    <p>N/A</p>
                  ) : (
                    <p>No se generó el documento</p>
                  )}
                </LabelGenerateDocuments>
              </div>
            </div>
          </>
        ) : null}
      </article>
    </section>
  )

  return template
}

export default OrderInfoPrint
